const closeFlash = (flash) => {
  const closeButton = flash.querySelector('.js-flash-close')

  if ( !flash.classList.contains('is-close') ) {
    window.setTimeout(() => {
      flash.classList.add('is-close')
    }, 5000)
  }

  closeButton.addEventListener('click', () => {
    flash.classList.add('is-close')
  })
}

export const InitFlash = () => {
  const flashes = document.querySelectorAll('.js-flash')

  for ( const flash of flashes ) {
    closeFlash(flash)
  }
}

export const FireFlash = (message, style) => {
  const container = document.querySelector('.js-flash-messages-box')
  const flash = document.querySelector('.js-flash')
  const content = flash.querySelector('p')
  flash.classList.add(`a-flash--${style}`)
  content.textContent = message
  flash.classList.remove('is-close')
  closeFlash(flash)
}
