import { createConsumer } from '@rails/actioncable'
import Rails from '@rails/ujs'
import { Controller } from "stimulus"
import { FireFlash } from 'components/flash'

const hideCounter = (counter) => {
  counter.removeAttribute('data-notification')
}

const hideNotifBubbles = () => {
  const notifBubbles = document.querySelectorAll('.js-notif-bubble')
  const favicon = document.getElementById('favicon')
  for (const notifBubble of notifBubbles) {
    notifBubble.style.display = 'none'
  }
  favicon.setAttribute('href', favicon.dataset.base)
  document.title = "YouLoveWords"
}

const getNotifications = (notifBox, currentCount) => {
  Rails.ajax({
    type: 'GET',
    url: `${location.origin}/notifications`,
    data: `count=${currentCount}`,
    success: function(response) {
      notifBox.innerHTML = response['notifications_list']
    },
    error: function(response){
      FireFlash('Cannot load messages', 'alert')
    }
  })
}

const updateNotifications = (box) => {
  Rails.ajax({
    type: 'PUT',
    url: `${location.origin}/notifications/mark_as_read`,
    success: function(response) {
      box.querySelector('.m-notification-link').classList.add('is-read')
    },
    error: function(response){
      FireFlash('Cannot load messages', 'alert')
    }
  })
}

export default class extends Controller {
  static targets = [ "box", "counter", "accountId" ]

  loadContent(event){
    getNotifications(this.boxTarget, 0)
  }

  loadMore(event){
    event.preventDefault()
    let currentNotifCount = document.getElementsByClassName("m-notification-item").length
    getNotifications(this.boxTarget, currentNotifCount)
  }

  markAllAsRead(event){
    updateNotifications(this.boxTarget)
    hideCounter(this.counterTarget)
    hideNotifBubbles()
  }

  connect() {
    let counter = this.counterTarget
    const cable  = createConsumer()
    const accountId = counter.dataset.accountId


    cable.subscriptions.create({
      channel: 'AccountChannel',
      account_id: accountId
    }, {
      received: (data) => {
        const favicon = document.getElementById('favicon')
        favicon.setAttribute('href', favicon.dataset.alert)
        if (counter.dataset.notification == null) {
          counter.dataset.notification = "·"
        }
      }
    })
  }
}
