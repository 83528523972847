const adjustPadding = (networkField) => {
  const prefixWidth = parseInt(window.getComputedStyle(networkField, ':before').width, 10)
  const input = networkField.querySelector('input')
  const inputPaddingLeft = parseInt(window.getComputedStyle(input, null).getPropertyValue('padding-left'), 10)

  input.style.paddingLeft = `${prefixWidth + inputPaddingLeft}px`
}

const removeNetworkUrl = (networkField) => {
  const prefix = networkField.dataset.prefix
  const input = networkField.querySelector('input')
  const value = input.value
  const valueSplitted = value.split(prefix)
  const afterPrefix = valueSplitted[valueSplitted.length - 1]

  input.value = afterPrefix
}

const initNetworkUrl = () => {
  const networkFields = document.getElementsByClassName('js-networkUrl')

  for (const networkField of networkFields) {
    adjustPadding(networkField)
    networkField.addEventListener('input', () => {
      removeNetworkUrl(networkField)
    })
  }
}

export default initNetworkUrl
