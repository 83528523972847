const readURL = (input, image) => {

  if (input.files && input.files[0]) {
    const reader = new FileReader()

    reader.onload = function (e) {
      image.setAttribute('src', e.target.result)
    }

    reader.readAsDataURL(input.files[0])
  }
}

const initCoverUploader = () => {
  const uploaders = document.querySelectorAll('.js-coverUpload')
  for ( const uploader of uploaders ) {
    const file = uploader.querySelector('.js-coverUpload-input')
    const image = uploader.querySelector('.js-coverUpload-image')

    if (file) {
      file.addEventListener('change', () => {
        readURL(file, image)
      })
    }
  }
}

export default initCoverUploader
