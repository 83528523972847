export const displayTags = (event) => {
  const tagsInputs = document.getElementsByClassName('js-tags-input');
  const category = event.currentTarget.value;
  var categoryTagsInput = document.getElementById(`js-tags-${category}`);

  if (category == "") {
    categoryTagsInput = document.getElementById('js-tags-all');
  }

  for (let input of tagsInputs) {
    input.style.display = 'none';
  }
  categoryTagsInput.style.display = 'block';
}
