import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import { submitForm, submitFormWithDelay, submitRemoteForm } from 'components/form_actions'
import { displayTags } from 'components/tags_select'
import { emptySearch } from 'components/search_select'

const EmptyTagSelects = (selects) => {
  for ( const select of selects ) {
    const event = new CustomEvent('emptyChoices')
    select.dispatchEvent(event)
  }
}

export default class extends Controller {
  submitForm = submitForm
  submitFormWithDelay = submitFormWithDelay
  submitRemoteForm = submitRemoteForm
  displayTags = displayTags

  updateSelectAndSubmit(event) {
    const selects = document.querySelectorAll('.js-tags-input select')
    EmptyTagSelects(selects)
    displayTags(event)
    submitForm(event)
  }
}
