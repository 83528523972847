import Pikaday from 'pikaday'
import I18n from './i18n'

const initPikaday = (dateInput, numberOfMonths, disablePreviousMonths) => {
  const currentYear = new Date().getFullYear()
  return new Pikaday({
    field: dateInput,
    numberOfMonths: numberOfMonths,
    i18n: I18n.t('javascript.date'),
    firstDay: 1,
    showDaysInNextAndPreviousMonths: true,
    enableSelectionDaysInNextAndPreviousMonths: false,
    format: 'DD/MM/YYYY',
    minDate: disablePreviousMonths ? new Date() : false,
    yearRange: [currentYear - 20, currentYear + 10],
    container: dateInput.closest('.o-modal') ? dateInput.closest('.m-form-group') : undefined,
  })
}

const initDatePicker = () => {
  const dateInputs = document.getElementsByClassName('js-pikaday')
  for ( const dateInput of dateInputs ) {
    const disablePreviousMonths = dateInput.dataset.disablePreviousMonths || false
    const pikaday = window.innerWidth > 799 ? initPikaday(dateInput, 2, disablePreviousMonths) : initPikaday(dateInput, 1, disablePreviousMonths)

    window.addEventListener('resize', () => {
      pikaday.destroy()
      if ( window.innerWidth > 799 ) {
        pikaday = initPikaday(dateInput, 2, disablePreviousMonths)
      } else {
        pikaday = initPikaday(dateInput, 1, disablePreviousMonths)
      }
    })
  }
}

export default initDatePicker
