import Popper from "popper.js"

const Dropdown = (container = document) => {
  const dropdowns = container.querySelectorAll('.js-dropdown')

  for ( const dropdown of dropdowns ) {
    const trigger = dropdown.querySelector('.js-dropdown-trigger')
    const popup = dropdown.querySelector('.js-dropdown-popup')
    const placement = dropdown.dataset.placement || 'bottom'
    const popper = new Popper(trigger, popup, {
      placement: placement
    })

    trigger.addEventListener('click', (e) => {
      e.stopPropagation()
      popper.update()
      popup.classList.toggle('is-open')
    })

    document.addEventListener('click', (e) => {
      if ( !dropdown.contains(e.target) ) {
        popup.classList.remove('is-open')
      }
    })

    popup.addEventListener('update', (e) => {
      popper.update()
    })
  }
}
export default Dropdown
