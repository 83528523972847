const EditableInfo = () => {
  const editableInfos = document.querySelectorAll('.js-editableInfo')

  for ( const editableInfo of editableInfos ) {
    const editButton = editableInfo.querySelector('.a-info-edit')
    const saveButton = editableInfo.querySelector('.a-info-save')
    const input = editableInfo.querySelector('.a-info-value')

    editButton.addEventListener('click', () => {
      editableInfo.classList.add('is-editable')
      input.removeAttribute('disabled')
      input.parentNode.removeAttribute('href')
      input.selectionStart = input.selectionEnd = input.value.length
      input.focus()
    })

    saveButton.addEventListener('click', () => {
      // DISABLED because it disables inputs before the values are sent to back
      // editableInfo.classList.remove('is-editable')
      // input.setAttribute('disabled', '')
    })
  }
}

export default EditableInfo
