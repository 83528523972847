import { createConsumer } from '@rails/actioncable'
import Rails from '@rails/ujs'
import { Controller } from "stimulus"
import { submitForm } from 'components/form_actions'
import { FireFlash } from 'components/flash'
import EmojiPicker from "components/emoji_picker";
import Dropdown from 'components/dropdown'
import Expandable from 'components/expandable'
import initModal from 'components/modal'
import cookie from 'cookie_js'

const expandInput = (input) => {
  input.style.height = ''
  input.style.height = `${input.scrollHeight}px`
}

const submitOnEnter = (e, input) => {
  if (e.keyCode == 13 && !e.shiftKey ) {
    e.preventDefault()
    if ( input.value && !input.classList.contains('prevent-submit') ) {
      submitForm(e)
      clearInput()
      deleteDraftMessage(e)
    }
  }
}

const toggleColumn = (opener) => {
  opener.closest('.o-chat-column').classList.toggle('is-open')
}

const scrollDown = (wall) => {
  wall.scrollTop = wall.scrollHeight;
  wall.classList.remove('is-loading')
}

const loadContent = (wall) => {
  if ( wall.scrollTop === 0 ) {
    if ( document.getElementById('js-firstMessage') === null && !wall.classList.contains('is-loading-content')) {
      displayMoreMessages(wall)
    }
  }
}

const toggleCounter = (counter) => {
  let count = parseInt(counter.innerHTML)
  if ( count > 0 ) {
    counter.style.display = 'inline-flex'
  } else {
    counter.style.display = 'none'
  }
}

const getPosition = (items, index) => {
  return Array.from(items).find((item, i) => i === index).offsetTop
}

const displayMoreMessages = (wall) => {
  const messagesCount = wall.children.length

  wall.classList.add('is-loading-content')

  Rails.ajax({
    type: 'GET',
    url: `${window.location.href}/messages?messages_count=${messagesCount + 10}`,
    success: function(response) {
      wall.innerHTML = response['messages_list']
      wall.classList.remove('is-loading-content')
      wall.scrollTop = getPosition(wall.children, wall.children.length - messagesCount)
    },
    error: function(response){
      FireFlash('Cannot load messages', 'alert')
      wall.classList.remove('is-loading-content')
    }
  })
}

const addMessageToList = (partial) => {
  let messagesList = document.getElementById('messages')
  messagesList.insertAdjacentHTML('beforeend', partial)
  scrollDown(messagesList)
}

const clearInput = () => {
  let input = document.getElementById('js-message-input')
  input.value = ''
  input.rows = 1
  expandInput(input)
}

const removeMessage = (messageId) => {
  let message = document.getElementById(`js-message-${messageId}`)
  message.remove();
}

const removeAttachment = (attachmentId) => {
  let attachment = document.getElementById(`js-attachment-${attachmentId}`)
  attachment.remove();
}

const reloadAttachmentsColumn = () => {
  const attachmentsColumn = document.getElementById('js-attachments-column')
  Rails.ajax({
    type: 'GET',
    url: `${window.location.href}/load_attachments_column`,
    success: function(response) {
      attachmentsColumn.innerHTML = response['partial']
      Dropdown(attachmentsColumn)
      Expandable(attachmentsColumn)
      initModal(attachmentsColumn)
    }
  })
}

const displayDraftMessage = (input) => {
  const key = input.form.getAttribute('data-draft-message-key')
  const draftMessage = cookie.get(key)
  if (draftMessage) {
    const decodedMessage = decodeURI(draftMessage)
    input.rows = decodedMessage.split(/\r\n|\r|\n/).length
    input.value = decodedMessage
  }
}

const deleteDraftMessage = (event) => {
  const key = event.target.form.getAttribute('data-draft-message-key')
  cookie.remove(key)
}

export default class extends Controller {

  storeDraftMessage(event) {
    const input = event.target
    const key = input.form.getAttribute('data-draft-message-key')
    cookie.set(key, input.value)
  }

  connect() {
    const roomId = this.data.get('id')
    const missionId = this.data.get('missionId')
    const cable = createConsumer()
    const chats = document.querySelectorAll('.o-chat')
    const openers = document.querySelectorAll('.o-chat-opener')
    const emojiPicker = document.querySelector('.js-emojiPicker')
    const messageInput = document.querySelector('.m-conversation-input')

    if(emojiPicker) {
      new EmojiPicker(emojiPicker)
    }

    if(messageInput) {
      displayDraftMessage(messageInput)
    }

    cable.subscriptions.create({
      channel: 'RoomChannel',
      room_id: roomId
    }, {
      received: (data) => {
        if (data['action'] == 'add_message') {
          if (window.account_role == 'client') {
            addMessageToList(data['anonymous_partial'])
          } else {
            addMessageToList(data['partial'])
          }
        } else if (data['action'] == 'remove_message') {
          removeMessage(data['message_id'])
        } else if (data['action'] == 'add_attachment') {
          reloadAttachmentsColumn()
        } else if (data['action'] == 'remove_attachment') {
          removeAttachment(data['attachment_id'])
        }
      }
    })

    cable.subscriptions.create({
      channel: 'MissionChannel',
      mission_id: missionId
    }, {
      received: (data) => {
        let counter = document.getElementById(`js-room-${data['room_id']}-counter`)
        if (!counter.parentElement.classList.contains('is-active')){
          let count = parseInt(counter.innerHTML) + data['message_count']
          if ( count > 0 )
            counter.innerHTML = count
          else {
            counter.innerHTML = 0
          }
          toggleCounter(counter)
        }
      }
    })

    for ( const chat of chats ) {
      const input = chat.querySelector('.m-conversation-input')
      const wall = chat.querySelector('.m-conversation-wall')

      window.addEventListener('load', () => {
        scrollDown(wall);
      })

      wall.addEventListener('scroll', () => {
        loadContent(wall)
      })

      if (input) {
        input.addEventListener('input', (e) => {
          expandInput(input)
        })
      }

      if (input) {
        input.addEventListener('keydown', (e) => {
          submitOnEnter(e, input)
        })
      }
    }

    for ( const opener of openers ) {
      opener.addEventListener('click', () => {
        toggleColumn(opener)
      })
    }
  }
}
