import 'lightgallery.js'

const Gallery = () => {
  const galleries = document.querySelectorAll('.o-gallery')

  for ( const gallery of galleries) {
    lightGallery(gallery, {
      download: false,
      counter: false,
      selector: '.o-gallery-link'
    })
  }
}

export default Gallery
