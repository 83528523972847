import Rails from 'rails-ujs'
import ValidateForm from 'components/form_validation'
import { FireFlash } from 'components/flash'

export const submitRemoteForm = (event) => {
  event.preventDefault();
  const form = event.currentTarget.form

  remoteSubmit(form);
}

export const remoteSubmit = (form) => {
  Rails.ajax({
    type: form.method,
    url: form.action,
    data: $(form).serialize(),
    success: function(response) {
      location.reload();
    },
    error: function(response){
      FireFlash(response.flash, 'alert')
      ValidateForm(response)
    }
  })
}

export const submitForm = (event) => {
  const form = event.currentTarget.form
  submit(form);
}

export const submitFormWithDelay = (event) => {
  const form = event.currentTarget.form
  const delay = 500
  clearTimeout(window.timer)
  window.timer = setTimeout(function(){
    submit(form);
  }, delay);
}

const submit = (form) => {
  let currentPath = window.location.pathname.replace(/\.[^/.]+$/, "")
  let serializedParams = `${currentPath}.html?${$(form).serialize()}`
  history.replaceState('', '', serializedParams);
  Rails.fire(form, 'submit')
}
