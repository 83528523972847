export const removeError = (input) => {
  const form = input.closest('.m-form')
  const group = input.closest('.m-form-group')
  const isList = group.classList.contains('m-form-group--list')
  const errors = isList ? [input, ...group.querySelectorAll('.m-form-group-label.has-error')] : group.querySelectorAll('.has-error')
  const feedbacks = isList ? input.parentNode.querySelectorAll('.m-form-group-feedback') : group.querySelectorAll('.m-form-group-feedback')

  for ( const error of errors ) {
    error.classList.remove('has-error')
  }

  for ( const feedback of feedbacks ) {
    feedback.remove()
  }

  group.classList.remove('m-form-group--error')

  if ( form && form.querySelectorAll('.has-error').length == 0 ) {
    if (form.getAttribute('custom-validation') == null) {
      form.querySelector('[type="submit"]').removeAttribute('disabled')
    }
  }
}

export const addError = (input, errorMessage) => {
  const form = input.closest('.m-form')
  const group = input.closest('.m-form-group')
  const label = group.querySelector('.m-form-group-label')
  const errorElement = document.createElement('div')
  removeError(input)
  errorElement.classList.add('m-form-group-feedback', 'has-error')
  errorElement.textContent = errorMessage
  input.parentNode.appendChild(errorElement)
  if (input) input.classList.add('has-error')
  if (label) label.classList.add('has-error')
  disableSubmit(form)
}

export const removeErrorOnInput = (input) => {
  input.addEventListener('change', () => {
    removeError(input)
  })

  input.addEventListener('input', () => {
    removeError(input)
  })
}

const disableSubmit = (form) => {
  form.querySelector('[type="submit"]').setAttribute('disabled', true)
}

const ValidateForm = (errorObj) => {
  const formId = errorObj['id']
  const className = errorObj['class_name']
  const errors = errorObj['errors']
  const form = document.getElementById(formId)

  for ( const error in errors ) {
    const errorMessage = errors[error].join(', ')
    const input = form.querySelector(`[name="${className}[${error}]"]`)

    removeError(input)
    addError(input, errorMessage)
    removeErrorOnInput(input)
  }
}

export const initFormValidation = (inputs) => {
  inputs = inputs || document.querySelectorAll('.m-form-group-input input, .m-form-group-input textarea, .m-form-group-input select')

  for ( const input of inputs ) {
    removeErrorOnInput(input)
  }
}

export default ValidateForm
