import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import { submitForm, submitFormWithDelay } from 'components/form_actions'
import { displayTags } from 'components/tags_select'


export default class extends Controller {
  submitForm = submitForm
  submitFormWithDelay = submitFormWithDelay
  displayTags = displayTags

  updateCoverInput(event) {
    const coverInput = document.getElementById('work_sample_cover')
    if (coverInput) {
      coverInput.value = event.target.value
    }
  }
}
