const readURL = (input, uploader) => {
  if (input.files && input.files[0]) {
    const reader = new FileReader()

    reader.onload = function (e) {
      uploader.style.backgroundImage = `url(${e.target.result})`
      uploader.classList.add('is-uploaded')
    }

    reader.readAsDataURL(input.files[0])
  } else {
    uploader.style.backgroundImage = ''
    uploader.classList.remove('is-uploaded')
  }
}

const initImageUploader = () => {
  const uploaders = document.getElementsByClassName('js-imageUpload')
  for ( const uploader of uploaders ) {
    const file = uploader.getElementsByClassName('js-imageUpload-input')[0]
    file.addEventListener('change', () => {
      readURL(file, uploader)
    })
  }
}

export default initImageUploader
