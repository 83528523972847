// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Cocoon from "../lib/cocoon"
import initChoices from 'components/choices'
import initDatePicker from 'components/datepicker'
import initImageUploader from 'components/image_uploader'
import initCoverUploader from 'components/cover_uploader'
import initMaxlength from 'components/max_length'
import initSearchSelect from 'components/search_select'
import Dropdown from 'components/dropdown'
import Expandable from 'components/expandable'
import initModal from 'components/modal'
import initRange from 'components/range'
import initFileInput from 'components/file_input'
import { InitFlash } from 'components/flash'
import EditableInfo from 'components/editable_info'
import Gallery from 'components/gallery'
import { initFormValidation } from 'components/form_validation'
import NetworkUrl from 'components/network_url'

const initCocoon = () => {
  Cocoon()
}

export default class extends Controller {
  connect() {
    initChoices()
    initDatePicker()
    initCocoon()
    initImageUploader()
    initCoverUploader()
    initMaxlength()
    initSearchSelect()
    Dropdown()
    Expandable()
    initModal()
    initRange()
    initFileInput()
    InitFlash()
    EditableInfo()
    Gallery()
    initFormValidation()
    NetworkUrl()
  }
}
