const openModal = function(e) {
  e.preventDefault()
  const modalId = this.getAttribute('href').split('#').pop()
  const modal = document.getElementById(modalId)
  modal.classList.add('is-open')
  modal.setAttribute('aria-hidden', false)
  document.querySelector('body').style.overflow = 'hidden';
}

const closeModal = function(e) {
  if ( e.currentTarget.classList.contains('js-modalClosure') ) {
  } else if ( e.target.classList.contains('o-modal-box') || e.target.closest('.o-modal-box') ) {
    return
  }
  const modal = this.closest('.js-modal')
  modal.classList.remove('is-open')
  modal.setAttribute('aria-hidden', true)
  document.querySelector('body').style.overflow = '';
}

const initModal = (container = document) => {
  const modalTriggers = container.getElementsByClassName('js-modalTrigger')
  const modalClosures = container.getElementsByClassName('js-modalClosure')
  const modalOverlays = container.getElementsByClassName('js-modal')

  for (const modalTrigger of modalTriggers) {
    modalTrigger.addEventListener('click', openModal)
  }

  for (const modalClosure of modalClosures) {
    modalClosure.addEventListener('click', closeModal)
  }

  for (const modalOverlay of modalOverlays) {
    modalOverlay.addEventListener('click', closeModal)
  }
}

export default initModal
