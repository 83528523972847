import { Controller } from 'stimulus'
import 'lightgallery.js'

export default class extends Controller {

  connect() {
    lightGallery(this.element, {
      download: false,
      counter: false,
      selector: '.js-lightbox'
    })
  }
}
