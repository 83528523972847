import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'city', 'latitude', 'longitude' ]

  connect() {
    google.maps.event.addDomListener(window, 'load', () => {
      if (this.hasCityTarget) {
        this.initializeAutocomplete(this.cityTarget)
      }
    })
  }

  initializeAutocomplete(element) {
    this.autocomplete = new google.maps.places.Autocomplete(element, { types: ['geocode'] });
    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      this.updateCoordinates()
    })

    element.addEventListener('input', () => {
      this.emptyCoordinates()
    })

    element.addEventListener('blur', () => {
      this.checkCoordinates(element)
    })
  }
  
  updateCoordinates() {
    const place = this.autocomplete.getPlace()
    this.latitudeTarget.value = place.geometry.location.lat()
    this.longitudeTarget.value = place.geometry.location.lng()
  }
  
  emptyCoordinates() {
    this.latitudeTarget.value = ''
    this.longitudeTarget.value = ''
  }
  
  checkCoordinates(element) {
    const latitude = this.latitudeTarget.value
    const longitude = this.longitudeTarget.value
    if ( !latitude || !longitude ) {
      element.value = ''
    }
  }
}
