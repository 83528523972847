import { Controller } from 'stimulus'
import { submitForm, submitFormWithDelay } from 'components/form_actions'


const arrayToParams = (array, paramName) => {
  let params = array.join(`&${paramName}[]=`)
  return `?${paramName}[]=${params}`
}

const extractOptionsFromMultiSelect = (select) => {
  return [...select.options].filter(option => option.selected).map(option => option.value)
}

export default class extends Controller {
  static targets = [ 'clientIds' ]
  submitForm = submitForm
  submitFormWithDelay = submitFormWithDelay

  reloadWithContacts(event) {
    window.location.href = `?company_id=${event.target.value}`;
  }

  addClientIdsToParams(event) {
    event.preventDefault()
    let options = extractOptionsFromMultiSelect(this.clientIdsTarget)
    window.location.href = event.target.href + arrayToParams(options, 'client_ids')
  }
}
