import Choices from 'choices.js'
import I18n from './i18n'

const emptyChoices = (choice) => {
  choice.removeActiveItems()
}

const cloneChoices = (choices) => {
  return choices.map((item) => ({
    value: item.value,
    label: item.label,
    selected: item.selected,
    disabled: item.disabled,
    customProperties: item.customProperties,
    placeholder: item.placeholder
  }))
}

const isTemp = (item) => {
  return item.customProperties && item.customProperties.temp
}

const removeTemp = (choices) => {
  return choices.filter((item) => !isTemp(item) || item.selected)
}

const isNotSelected = (currentChoices, newItem) => {
  const selectedChoices = currentChoices.filter(choice => choice.selected)

  for (const choice of selectedChoices) {
    if ( newItem.value.toLowerCase() == choice.value.toLowerCase() ) return false
  }

  return true
}

const updateChoice = (choice, newItem) => {
  const choices = choice._currentState.choices
  const currentChoices = cloneChoices(removeTemp(choices))

  if ( newItem ) {
    newItem.value = newItem.value.trim()
    if ( newItem.value && isNotSelected(currentChoices, newItem) ) currentChoices.unshift(newItem)
  }

  if (typeof choice.removeActiveItems === "function") choice.removeActiveItems()
  choice.setChoices(currentChoices, 'value', 'label', true)
}

const initSingleSelect = () => {
  const selects = document.getElementsByClassName('js-choice')
  for ( const select of selects ) {
    const customChoice = select.dataset.custom != null
    const placeholder = select.getAttribute('placeholder')
    const options = select.querySelectorAll('option')
    const hasSelectedItem = Array.from(options).filter(option => option.getAttribute('selected') != null).length
    for (const option of options) {
      if ( option.innerHTML === '' ) option.remove()
    }
    const choice = new Choices(select, {
      shouldSort: false,
      choices: placeholder ? [{
        value: placeholder,
        disabled: true,
        placeholder: true
      }] : [],
      callbackOnInit() {
        if ( placeholder ) {
          if ( hasSelectedItem ) return
          this.setChoiceByValue(placeholder)
        }

        if (customChoice) {
          const input = this.input.element

          input.addEventListener('input', () => {
            const newChoice = {
              value: input.value,
              customProperties: {
                temp: true
              }
            }

            if ( input.value ) updateChoice(choice, newChoice)
          })

          input.addEventListener('blur', () => {
            const newChoice = {
              value: input.value,
              customProperties: {
                temp: true
              },
              selected: true,
            }

            if ( input.value ) updateChoice(choice, newChoice)
          })

          select.addEventListener('change', (e) => {
            updateChoice(choice)
          })
        }
      }
    })

    select.addEventListener('emptyChoices', () => {
      emptyChoices(choice)
    })
  }
}

const initMultipleSelect = () => {
  const multipleSelects = document.getElementsByClassName('js-choiceMultiple')
  for ( const multipleSelect of multipleSelects ) {
    const limit = multipleSelect.dataset.limit || -1
    const limitText = multipleSelect.dataset.limitText
    const customChoice = multipleSelect.dataset.custom != null
    const lockedOption = multipleSelect.dataset.lockedOption
    const choice = new Choices(multipleSelect, {
      removeItemButton: true,
      shouldSort: false,
      maxItemCount: limit,
      maxItemText: limitText,
      noResultsText: I18n.t('javascript.choices.noResultsText'),
      noChoicesText: I18n.t('javascript.choices.noChoicesText'),
      callbackOnCreateTemplates(template) {
        const classNames = this.config.classNames;
        return {
          choice(classNames, data) {
            return template(`
              <div class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}" data-select-text="${this.config.itemSelectText}" data-choice ${data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'} data-id="${data.id}" data-value="${data.value}" ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'} ${typeof(data.customProperties) === 'string' ? `data-tag="${data.customProperties}"` : ""}>
                <span>${data.label}</span>
              </div>
            `)
          },
        };
      },
      callbackOnInit() {
        const input = this.input.element

        if (lockedOption) {
          this.containerInner.element.dataset.lockedOption = lockedOption;
        }

        input.addEventListener('blur', () => {
          choice.clearInput()
        })

        if (customChoice) {
          input.addEventListener('input', () => {
            const newChoice = {
              value: input.value,
              customProperties: {
                temp: true
              }
            }

            if ( input.value ) updateChoice(choice, newChoice)
          })

          multipleSelect.addEventListener('change', (e) => {
            updateChoice(choice)
          })
        }
      }
    })

    multipleSelect.addEventListener('emptyChoices', () => {
      emptyChoices(choice)
    })
  }
}

const initSimpleSelect = () => {
  const simpleSelects = document.getElementsByClassName('js-choiceSimple')
  for ( const simpleSelect of simpleSelects ) {
    const outline = simpleSelect.classList.contains('a-inputSelect--outline') ? ' choices--outline' : ''
    const placeholder = simpleSelect.getAttribute('placeholder')
    const options = simpleSelect.querySelectorAll('option')
    const hasSelectedItem = Array.from(options).filter(option => option.getAttribute('selected') != null).length
    for (const option of options) {
      if ( option.innerHTML === '' ) option.remove()
    }
    const choice = new Choices(simpleSelect, {
      searchEnabled: false,
      shouldSort: false,
      choices: placeholder ? [{
        value: placeholder,
        disabled: true,
        placeholder: true
      }] : [],
      classNames: {
        containerOuter: 'choices choices--simple' + outline
      },
      callbackOnInit() {
        if ( placeholder ) {
          if ( hasSelectedItem ) return
          this.setChoiceByValue(placeholder)
        }
      }
    })
  }
}

const initChoices = () => {
  initSingleSelect()
  initMultipleSelect()
  initSimpleSelect()
}

export default initChoices
