const setOutput = function(range) {
  const value = range.querySelector('input').value
  const output = range.querySelector('output')
  const min = range.dataset.min
  const max = range.dataset.max

  output.textContent = value
  output.style.left = `calc(${100 / (max - min) * value}% - ${2 / (max - min) * value}rem)`
}

const initRange = () => {
  const ranges = document.getElementsByClassName('js-range')

  for (const range of ranges) {
    setOutput(range)
    range.addEventListener('input', () => {
      setOutput(range)
    })
  }
}

export default initRange
