import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import { submitRemoteForm, submitForm, submitFormWithDelay } from 'components/form_actions'
import { FireFlash } from 'components/flash'
import I18n from 'components/i18n'

const updateCounter = (number) => {
  const counter = document.getElementById('js-proposal-counter')
  const count = parseInt(counter.innerHTML)
  counter.innerHTML = count + number
}

const createProposal = (event) => {
  event.preventDefault()
  const link = event.currentTarget
  const tooltip = link.closest('.a-tooltip')
  link.classList.add('is-loading')
  if ( tooltip ) {
    tooltip.dataset.tooltip = I18n.t('javascript.talents.remove')
  }

  Rails.ajax({
    type: 'POST',
    url: link.getAttribute('data-url'),
    success: function(response){
      link.classList.remove('is-loading')
      link.classList.add('is-checked')
      updateCounter(1)
    },
    error: function(response){
      FireFlash(response.flash, 'alert')
      link.classList.remove('is-loading')
    }
  })
}

const destroyProposal = (event) => {
  event.preventDefault()
  const link = event.currentTarget
  const tooltip = link.closest('.a-tooltip')
  link.classList.remove('is-checked')
  link.classList.add('is-loading')
  if ( tooltip ) {
    tooltip.dataset.tooltip = I18n.t('javascript.talents.add')
  }

  Rails.ajax({
    type: 'DELETE',
    url: link.getAttribute('data-url'),
    success: function(response){
      link.classList.remove('is-loading')
      updateCounter(-1)
    },
    error: function(response){
      FireFlash(response.flash, 'alert')
      link.classList.remove('is-loading')
      link.classList.add('is-checked')
    }
  })
}

export default class extends Controller {
  submitFormWithDelay = submitFormWithDelay;
  submitRemoteForm = submitRemoteForm;
  submitForm = submitForm;

  static targets = ['legalStatus', 'legalStatusInput']

  toggleProposal(event){
    event.preventDefault()
    const link = event.currentTarget

    if(link.classList.contains('is-checked')) {
      destroyProposal(event)
    } else {
      createProposal(event)
    }
  }

  toggleLegalStatusInput(event){
    event.preventDefault()
    this.legalStatusTarget.classList.toggle('u-hidden')
    this.legalStatusInputTarget.classList.toggle('u-hidden')
  }

  confirmTag(event) {
    event.preventDefault()
    const button = event.currentTarget
    Rails.ajax({
      type: 'PATCH',
      url: button.getAttribute('data-url'),
      success: function(response) {
        button.classList.toggle('is-active')
      },
      error: function(response){
        FireFlash(response.flash, 'alert')
      }
    })
  }
}
