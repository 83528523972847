import { Controller } from "stimulus"


export default class extends Controller {

  static targets = [ "input", "dropdownPopup", "mentionableItem" ]

  connect() {
    if(this.hasInputTarget) {
      this.inputTarget.addEventListener('keydown', (e) => {
        if (e.keyCode == 13 && !e.shiftKey)
          this.handleReturnKeypress(e)
        else if (e.keyCode == 40 && !e.shiftKey)
          this.handleKeydownpress(e)
        else if (e.keyCode == 38 && !e.shiftKey)
          this.handleKeyuppress(e)
      })
    }
  }

  typing(event) {
    const input = event.target
    if(input.value.indexOf("@") !== -1) {
      this.open()
      this.filterMentionableItems(input)
      this.selectFirstMentionnable()
    }
    else {
      this.close()
    }
  }

  selected(event){
    const name = event.target.closest('[data-mentionable-name]').getAttribute("data-mentionable-name")
    this.selectName(name)
  }

  handleReturnKeypress(event) {
    if (this.isOpen() ) {
      event.preventDefault()
      this.selectName(this.getSelectedMentionable().getAttribute("data-mentionable-name"))
    }
  }

  handleKeydownpress(event) {
    if (this.isOpen()) {
      event.preventDefault()
      this.selectNextMentionable()
    }
  }
  handleKeyuppress(event) {
    if (this.isOpen()) {
      event.preventDefault()
      this.selectPreviousMentionable()
    }
  }

  getSelectedMentionable() {
    for (let mentionableItem of this.mentionableItemTargets) {
      if (mentionableItem.classList.contains('is-selected')) {
        return mentionableItem;
      }
    }
  }
  close() {
    for (let mentionableItem of this.mentionableItemTargets) {
      mentionableItem.classList.remove('is-selected')
    }
    this.dropdownPopupTarget.classList.remove('is-open')
    this.inputTarget.classList.remove('prevent-submit')
  }

  open() {
    if(!this.isOpen())
      this.dropdownPopupTarget.style.marginLeft = `${this.getCursorCoordinates()}px`
    this.dropdownPopupTarget.classList.add('is-open')
    this.inputTarget.classList.add('prevent-submit')
  }

  isOpen(){
    return this.dropdownPopupTarget.classList.contains('is-open')
  }

  selectName(name) {
    this.close()
    const cursorPosition = this.inputTarget.selectionStart
    const textBeforeCursor = this.inputTarget.value.substring(0, cursorPosition);
    const textAfterCursor = this.inputTarget.value.substring(cursorPosition)
    const mentionStartIndex = textBeforeCursor.lastIndexOf('@')
    const textBeforeCursorWithoutMention = textBeforeCursor.substring(0, mentionStartIndex);
    this.inputTarget.value = textBeforeCursorWithoutMention + '@' + name + ' ' + textAfterCursor
    const newCursorPosition = mentionStartIndex + name.length + 2 // +2 pour '@' et ' '
    this.inputTarget.setSelectionRange(newCursorPosition, newCursorPosition)
    this.inputTarget.focus()
  }

  filterMentionableItems() {
    const mention = this.currentMention()
    var matchingNamesCount = 0;
    this.mentionableItemTargets.forEach((mentionableItem) => {
      const name = mentionableItem.querySelector(".a-avatar-name").textContent
      mentionableItem.classList.remove('is-hidden', 'is-selected')
      if(name.toLowerCase().startsWith(mention.toLowerCase())){
        mentionableItem.classList.remove('is-hidden')
        matchingNamesCount += 1
      } else {
        mentionableItem.classList.add('is-hidden')
      }
    })
    if(matchingNamesCount === 0) {
      this.close()
    }
    else {
      this.dropdownPopupTarget.dispatchEvent(new Event('update'))
    }
  }

  selectFirstMentionnable(){
    for (let mentionableItem of this.mentionableItemTargets) {
      if (!mentionableItem.classList.contains('is-hidden')) {
        mentionableItem.classList.add('is-selected');
        break;
      }
    }
  }

  currentMention() {
    const input = this.inputTarget
    const cursorPos = input.selectionStart
    const textBeforeCursor = input.value.substring(0, cursorPos)
    const atPos = textBeforeCursor.lastIndexOf('@')
    if (atPos === -1) {
      return ''
    }
    return textBeforeCursor.substring(atPos + 1)
  }

  getCursorCoordinates() {
    const textarea = this.inputTarget
    const text = textarea.value.substring(0, textarea.selectionStart);
    const dummyDiv = document.createElement('div');
    dummyDiv.style.position = 'absolute';
    dummyDiv.style.visibility = 'hidden';
    dummyDiv.style.whiteSpace = 'pre-wrap';
    dummyDiv.style.wordWrap = 'break-word';
    dummyDiv.style.width = `${textarea.offsetWidth}px`;
    dummyDiv.style.font = getComputedStyle(textarea).font;
    dummyDiv.textContent = text.replace(/\n/g, '\n ');

    document.body.appendChild(dummyDiv);

    const span = document.createElement('span');
    span.textContent = '|';
    dummyDiv.appendChild(span);

    const x = span.offsetLeft;

    document.body.removeChild(dummyDiv);

    return x;
  }

  selectNextMentionable() {
    const current = this.getSelectedMentionable()
    let next = current.nextElementSibling
    while (next) {
      if (next && !next.classList.contains('is-hidden')) {
        next.classList.add('is-selected')
        current.classList.remove('is-selected')
        break
      } else {
        next = next.nextElementSibling
      }
    }
  }

  selectPreviousMentionable() {
    const current = this.getSelectedMentionable()
    let previous = current.previousElementSibling
    while (previous) {
      if(previous && !previous.classList.contains('is-hidden')) {
        previous.classList.add('is-selected')
        current.classList.remove('is-selected')
        break
      }
      else {
        previous = previous.previousElementSibling
      }
    }
  }

}
