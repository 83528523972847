import Choices from 'choices.js'

const emptySearch = (search) => {
  const selects = search.querySelectorAll('.a-search-field select')
  const inputs = search.querySelectorAll('.a-search-field input')

  for ( const select of selects ) {
    const event = new CustomEvent('emptyChoices')
    select.dispatchEvent(event)
  }

  for ( const input of inputs ) {
    input.value = ""
  }
}

const selectSearch = (search, select) => {
  const value = select.value
  const fields = search.querySelectorAll('.a-search-field')
  for ( const field of fields ) {
    field.classList.add('is-hidden')
  }
  search.querySelector(`.a-search-field[data-select="${value}"]`).classList.remove('is-hidden')
}

const initSearchSelect = () => {
  const searches = document.getElementsByClassName('js-searchSelect')
  for ( const search of searches ) {
    const select = search.querySelector('.a-search-select select')

    selectSearch(search, select)

    select.addEventListener('change', () => {
      emptySearch(search)
      selectSearch(search, select)
    })
  }
}

export default initSearchSelect
