import { Controller } from 'stimulus'
import { remoteSubmit } from '../components/form_actions'

export default class extends Controller {
    static targets = [ 'input', 'preview', 'list', 'option' ]

    initialize() {
        const uploadcareInput = this.inputTarget;
        const submitOnChange = uploadcareInput.dataset.submit;
        this.widget = uploadcare.Widget(uploadcareInput);
        let init = this.inputTarget.value != "";

        this.widget.onChange((response) => {
            if (response) {
                this.emptyList()
                const files = response.files ? response.files() : [response]
                for ( const file of files ) {
                    file.promise()
                    .progress(() => {
                        this.element.classList.add('is-uploading')
                    })
                    .done((info) => {
                        this.element.classList.remove('is-uploading')
                        this.element.classList.add('is-uploaded')
                        this.showPreview(info)
                        this.addToList(info)
                        if ( submitOnChange && !init ) {
                            remoteSubmit(uploadcareInput.form);
                        }
                        init = false
                    })
                }
            }
        })
    }

    openDialog() {
        this.widget.openDialog()
    }

    listItem(info) {
        const item = document.createElement('div')
        item.classList.add('m-fileInput-item')
        item.innerHTML = `
            ${info.isImage ? `
                <div class="m-fileInput-thumb" style="background-image: url(${info.cdnUrl}-/preview/200x200/)"></div>
            ` : `
                <div class="m-fileInput-thumb">
                    <span class="a-icon"><svg width="24" height="24" viewBox="0 0 100 100"><use xlink:href="#icon-file"></use></svg></span>
                </div>
            `}
            <div class="m-fileInput-name">${info.name}</div>
            <div class="m-fileInput-action">
                <span class="a-icon"><svg width="24" height="24" viewBox="0 0 100 100"><use xlink:href="#icon-edit"></use></svg></span>
            </div>
        `
        return item
    }
    
    addToList(info) {
        if ( this.hasListTarget ) {
            this.listTarget.appendChild(this.listItem(info))
        }
    }
    
    emptyList() {
        if ( this.hasListTarget ) {
            this.listTarget.innerHTML = ''
        }
    }
    
    showPreview(info) {
        if ( this.hasPreviewTarget && info.isImage ) {
            if ( this.hasOptionTarget ) {
                const previewOption = this.previewTarget
                const selectedOption = this.optionTargets.filter(option => option.value === info.originalUrl)[0]

                if ( selectedOption ) {
                    selectedOption.setAttribute('checked', true)
                    previewOption.classList.add('u-hidden')
                } else {
                    const image = previewOption.querySelector('img') || document.createElement('img')
                    image.setAttribute('src', info.originalUrl + '-/preview/200x200/')
                    previewOption.querySelector('label').appendChild(image)
                    previewOption.querySelector('input').setAttribute('checked', true)
                    previewOption.classList.remove('u-hidden')
                }
            } else {
                if ( this.previewTarget.nodeName === 'IMG' ) {
                    this.previewTarget.setAttribute('src', info.cdnUrl)
                } else {
                    this.previewTarget.style.backgroundImage = `url(${info.cdnUrl})`
                }
            }
        }
    }

    selectImage(e) {
        this.widget.value(e.target.value)
        this.openDialog()
    }
}
