const imageExtensions = ['png', 'jpg', 'jpeg', 'svg', 'gif']

const createPreviewElement = () => {
  const previewElement = document.createElement('div')
  previewElement.classList.add('m-fileInput-item')
  return previewElement
}

const createThumbElement = () => {
  const thumbElement = document.createElement('div')
  thumbElement.classList.add('m-fileInput-thumb')
  thumbElement.innerHTML = `<span class="a-icon"><svg width="24" height="24" viewBox="0 0 100 100"><use xlink:href="#icon-file"></use></svg></span>`
  return thumbElement
}

const createNameElement = (name) => {
  const nameElement =  document.createElement('div')
  nameElement.classList.add('m-fileInput-name')
  nameElement.textContent = name
  return nameElement
}

const createDeleteElement = (previewElement) => {
  const deleteElement =  document.createElement('button')
  deleteElement.setAttribute('type', 'button')
  deleteElement.classList.add('m-fileInput-delete', 'js-fileInput-delete')
  deleteElement.innerHTML = '<span class="a-icon"><svg width="24" height="24" viewBox="0 0 100 100"><use xlink:href="#icon-remove"></use></svg></span>'

  deleteElement.addEventListener('click', () => {
    previewElement.remove()
  })

  return deleteElement
}

const createNewInput = (input) => {
  const field = input.closest('.m-fileInput-field')
  const newInput = input.cloneNode()
  newInput.classList.add('js-fileInput-input')
  newInput.value = ''
  field.appendChild(newInput)
}

const imagePreview = (file, preview, extension) => {
  if (imageExtensions.includes(extension.toLowerCase())) {
    preview.style.backgroundImage = `url(${file.target.result})`
  }
}

const isFileSizeOver = (file, size) => {
  return file.loaded > size
}

const showAlertMessage = (input, alertMessage) => {
  const inputGroup = input.closest('.m-form-group')
  const errorElement = document.createElement('div')
  errorElement.classList.add('m-form-group-feedback', 'has-error')
  errorElement.textContent = alertMessage
  inputGroup.closest('.m-form-group').appendChild(errorElement)
}

const addPreviewItem = (field, input, preview, file) => {
  const fileName = input.value.split(/(\\|\/)/g).pop()
  const extension = fileName.split(/\./g).pop()
  const previewElement = createPreviewElement()
  const thumbElement = createThumbElement()
  const nameElement =  createNameElement(fileName)
  const deleteElement = createDeleteElement(previewElement)
  const limitMessage = field.dataset.limit;

  if ( limitMessage && isFileSizeOver(file, 50000000) ) {
    showAlertMessage(input, limitMessage)
    return false
  }

  createNewInput(input)
  preview.appendChild(previewElement)
  previewElement.appendChild(thumbElement)
  previewElement.appendChild(nameElement)
  previewElement.appendChild(deleteElement)
  previewElement.appendChild(input)
  input.classList.replace('js-fileInput-input', 'u-visuallyHidden')
  input.id = ''
  initFileInput()
  imagePreview(file, thumbElement, extension)
}

const removePreviewItem = (previewItem) => {
  previewItem.remove()
}

const changeValue = (field, input, file) => {
  const fileName = input.value.split(/(\\|\/)/g).pop()
  const limitMessage = field.dataset.limit;

  if ( limitMessage && isFileSizeOver(file, 50000000) ) {
    showAlertMessage(input, limitMessage)
    input.value = ""
    field.removeAttribute('value')
    return false
  }

  field.setAttribute('value', fileName)
}

const initFileInput = () => {
  const fileFields = document.getElementsByClassName('js-fileInput')
  for ( const fileField of fileFields ) {
    const input = fileField.querySelector('.js-fileInput-input')
    const preview = fileField.querySelector('.m-fileInput-preview')
    const deleteButtons = fileField.querySelectorAll('.js-fileInput-delete')
    const isSingle = fileField.dataset.single

    input.addEventListener('change', () => {
      if ( input.value ) {
        const reader = new FileReader()
        reader.onload = function (file) {
          if ( isSingle ) {
            changeValue(fileField, input, file)
          } else {
            addPreviewItem(fileField, input, preview, file)
          }
        }
        reader.readAsDataURL(input.files[0])
      } else {
        fileField.removeAttribute('value')
      }
    })

    for ( const deleteButton of deleteButtons ) {
      deleteButton.addEventListener('click', () => {
        const previewItem = deleteButton.closest('.m-fileInput-item')
        removePreviewItem(previewItem)
      })
    }
  }
}

export default initFileInput
