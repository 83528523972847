const initMaxlength = () => {
  const fields = document.getElementsByClassName('js-maxlength')
  for ( const field of fields ) {
    const input = field.querySelector('input')
    const counter = field.querySelector('.a-bubble')
    const maxlength = input.getAttribute('maxlength')

    counter.textContent = maxlength - input.value.length
    input.addEventListener('input', () => {
      counter.textContent = maxlength - input.value.length
    })
  }
}

export default initMaxlength
