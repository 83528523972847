import { removeError, initFormValidation } from './form_validation'

export const addInput = (event) => {
  event.preventDefault();
  const inputsList = event.target.closest('.m-form-group').querySelector('.js-input-list');
  const inputGroup = inputsList.lastChild;
  const newField = inputGroup.cloneNode(true);
  const input = newField.querySelector('input')
  input.value = '';
  inputsList.append(newField);
  removeError(newField);
  initFormValidation([input]);
}

export const emptyInput = (event) => {
  event.preventDefault();
  const inputGroup = event.target.parentNode;
  inputGroup.querySelector('input').value = '';
}
