const Expandable = (container = document) => {
  const expandables = container.querySelectorAll('.js-expandable')

  for ( const expandable of expandables ) {
    const trigger = expandable.querySelector('.js-expandable-trigger')
    const slide = expandable.querySelector('.js-expandable-slide')
    if ( !slide ) return
    const innerElement = slide.firstElementChild
    let closing = false
    let height = 0

    trigger.addEventListener('click', (e) => {
      e.preventDefault()
      if ( closing ) return false
      innerElement.style.overflow = 'hidden'
      height = innerElement.offsetHeight
      slide.style.overflow = 'hidden'
      slide.style.height = `${height}px`

      if ( expandable.classList.contains('is-close') ) {
        expandable.classList.remove('is-close')
      } else {
        window.setTimeout(function () {
      		slide.style.height = 0
          closing = true
      	}, 10);
      }
    })

    slide.addEventListener('transitionend', () => {
      slide.style.height = ''
      slide.style.overflow = ''
      innerElement.style.overflow = ''

      if ( closing ) {
        expandable.classList.add('is-close')
        closing = false
      }
    })
  }
}

export default Expandable
