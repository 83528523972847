import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import { FireFlash } from 'components/flash'

const updateCounter = (scope) => {
  const counter = document.getElementById(`js-${scope}-counter`)
  const count = parseInt(counter.innerHTML)
  counter.innerHTML = count-1
}

const removeTalentElements = (talentId) => {
  const row = document.getElementById(`js-talent-row-${talentId}`)
  row.parentElement.removeChild(row)

  const modalInput = document.getElementById(`js-talent-input-${talentId}`)
  if (modalInput) {
    modalInput.parentElement.removeChild(modalInput)
  }
}


export default class extends Controller {
  static targets = [ "createdCounter", "acceptedCounter", "sentCounter", "declinedCounter" ]

  submitProposal(event){
    event.preventDefault()
    const talentId = event.currentTarget.getAttribute('data-id')
    const scope = event.currentTarget.getAttribute('data-scope')

    Rails.ajax({
      type: event.currentTarget.getAttribute('data-ajaxMethod'),
      url: event.currentTarget.getAttribute('data-url'),
      success: function(response){
        removeTalentElements(talentId)
        updateCounter(scope)
      },
      error: function(response) {
        FireFlash(response.flash, 'alert')
      }
    })
  }
}
