import { Controller } from 'stimulus'
import { addError } from 'components/form_validation'
import I18n from 'components/i18n'

export default class extends Controller {
  connect() {
    const input = this.element
    const type = input.getAttribute('type')

    if ( type === 'url' ) {
      input.addEventListener('input', () => {
        const value = input.value
        if ( 'http://'.startsWith(value.substring(0, 7).toLowerCase()) || 'https://'.startsWith(value.substring(0, 8).toLowerCase()) ) {
          input.value = value.trim()
        } else {
          input.value = 'http://' + value.trim().replace(/^https?\:?\/?/i, '')
        }
      })
      
      input.addEventListener('blur', () => {
        const regex = new RegExp(/.+\..+/)
        if ( input.value && !input.value.match(regex) ) {
          addError(input, I18n.t('javascript.errors.messages.url'))
        }
      })
    }
  }
}
