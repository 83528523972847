import { Controller } from 'stimulus'
import { addError } from 'components/form_validation'
import I18n from 'components/i18n'
import intlTelInput from 'intl-tel-input'

export default class extends Controller {
  static targets = [ 'phone', 'name', 'confirmationEmail' ]

  showLoader() {
    this.element.classList.add('is-loading')
  }

  initIntPhone() {
    if ( this.hasPhoneTarget ) {
      const initialValue = this.phoneTarget.value

      this.intPhone = intlTelInput(this.phoneTarget, {
        initialCountry: "fr",
        preferredCountries: ["fr", "be"],
        hiddenInput: this.phoneTarget.getAttribute('name').replace( /(^.*\[|\].*$)/g, '' ),
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.4/js/utils.js",
        separateDialCode: true
      })
      this.intPhone.hiddenInput.value = initialValue
      this.phoneTarget.addEventListener('change', () => this.updateIntPhone())
      this.phoneTarget.addEventListener('countrychange', () => this.updateIntPhone())
    }
  }

  updateIntPhone() {
    this.intPhone.hiddenInput.value = this.intPhone.getNumber()
  }

  initName() {
    for (const name of this.nameTargets) {
      name.addEventListener('blur', () => {
        if ( name.value.replace(/\W/g, '').length <= 1 ) {
          addError(name, I18n.t('javascript.errors.messages.tooShort'))
        }
      })
    }
  }

  initConfirmationEmail() {
    if ( this.hasConfirmationEmailTarget ) {
      this.confirmationEmailTarget.addEventListener('paste', (e) => {
        e.preventDefault()
        return false
      })
    }
  }

  toggleSubmit(e) {
    const submitButton = e.target.form.querySelector('[type="submit"]')
    submitButton.disabled = e.target.value == ''
  }

  connect() {
    this.initIntPhone()
    this.initName()
    this.initConfirmationEmail()

    this.element.setAttribute('novalidate', true)
    this.element.addEventListener('submit', (e) => {
      if ( this.element.dataset.loader ) {
        this.showLoader()
      }
    })
  }
}
